import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["type", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['c-vue-button', _ctx.componentClasses])
  }, [
    _createElementVNode("button", {
      class: "vue-button-button",
      type: _ctx.type,
      disabled: _ctx.disabled,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('buttonClick')))
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 8, _hoisted_1)
  ], 2))
}