import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "lights-out-grid" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "lights-out-cell-mark" }
const _hoisted_4 = { class: "lights-out-controls" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueButton = _resolveComponent("VueButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['c-lights-out', 'is-greyscale-' + _ctx.colors])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.gameGrid, (gridRow, gridIndex) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "lights-out-row",
          key: `row${gridIndex}`
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(gridRow, (gridCell, cellIndex) => {
            return (_openBlock(), _createElementBlock("div", {
              key: `cell${cellIndex}`,
              class: _normalizeClass(['lights-out-cell', 'is-cell-color-' + gridCell]),
              onClick: ($event: any) => (_ctx.cellClick({ row: gridIndex, column: cellIndex }))
            }, [
              _createElementVNode("div", _hoisted_3, _toDisplayString(gridCell), 1)
            ], 10, _hoisted_2))
          }), 128))
        ]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_VueButton, {
        type: "button",
        onClick: _ctx.returnLastStep,
        disabled: _ctx.historyIsEmpty
      }, {
        default: _withCtx(() => [
          _createTextVNode("Step back")
        ]),
        _: 1
      }, 8, ["onClick", "disabled"]),
      _createVNode(_component_VueButton, {
        type: "button",
        class: "is-cancel",
        onClick: _ctx.quitGame
      }, {
        default: _withCtx(() => [
          _createTextVNode("Quit game")
        ]),
        _: 1
      }, 8, ["onClick"])
    ])
  ], 2))
}